import { useRef, useState } from 'react';

import { SendIcon, TrashIcon, Button, SpinnerIcon } from '@kindlyhuman/component-library';

import { useUser } from '../../../../hooks/useUser';
import { Dependent, useInviteDependents } from '../../../../hooks/useInviteDependents';

import { DependentCard } from '../../../invite_dependents/dependent_card';
import { RemoveDependentModal } from '../../../invite_dependents/remove_dependent_modal';
import { InviteDependentsForm } from '../../../invite_dependents/invite_dependents_form';

import { normalizePhoneNumber } from '../../../common/normalize-phone-input';

import { FormBlock } from '../../../common/form_block';
import Toast from '../../../common/PopUpMessage';

import { TabHeader } from '../tab_header';

export interface NotificationsProfileData {
  notifications_listener_online: boolean;
  notifications_check_ins: boolean;
}

export const InviteDependentsTab = () => {
  const toastNotificationContainerRef = useRef<HTMLDivElement>(null);

  const [dependentToRemove, setDependentToRemove] = useState<Dependent | undefined>(undefined);

  const { data: user, isLoading: isUserLoading } = useUser();
  const { data: inviteDependents, isLoading: isInviteDependentsLoading, resendInvite } = useInviteDependents(user?.id);

  const isLoading = isUserLoading || isInviteDependentsLoading;
  const maxNumDependents = user?.caller_role?.active_subscription?.package.dependents_configuration.dependent_count;
  const canUserAddDependents =
    user?.caller_role?.active_subscription?.package.dependents_configuration.members_can_add_dependents;

  const resendInviteDependent = (dependentId: number) => {
    return new Promise((res) =>
      resendInvite.mutate(dependentId, {
        onSuccess: () => {
          Toast.success('Invite email sent again!', undefined, toastNotificationContainerRef?.current);

          res(null);
        },
        onError: (error) => {
          // @ts-ignore
          const errorMessage = error?.response?.data?.description;

          if (errorMessage) {
            Toast.error(
              `There was an error resending the invite. ${
                errorMessage ? errorMessage : 'Please contact your administrator.'
              }`,
              undefined,
              toastNotificationContainerRef?.current,
            );
          }

          res(error);
        },
      }),
    );
  };

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <>
      <div ref={toastNotificationContainerRef} />
      <TabHeader
        header="Invite dependents"
        subHeader="Your plan provides access for up to 3 dependents. You can invite them below to access your benefit."
      />

      {inviteDependents && inviteDependents.length > 0 && (
        <FormBlock header="My dependents">
          {inviteDependents.map(({ id, first_name, last_name, email_address, mobile_phone, invite_status }) => (
            <DependentCard
              key={id}
              className="w-full"
              displayName={`${first_name} ${last_name}`}
              emailAddress={email_address}
              phoneNumber={normalizePhoneNumber(mobile_phone.replace('+1', ''))}
              pending={invite_status === 'pending'}
              menuItems={[
                {
                  onClick: () => {
                    resendInviteDependent(id);
                  },
                  title: 'Resend',
                  icon: <SendIcon />,
                },
                {
                  onClick: () => {
                    setDependentToRemove({ id, first_name, last_name } as Dependent);
                  },
                  title: 'Remove',
                  icon: <TrashIcon />,
                },
              ]}
            />
          ))}
        </FormBlock>
      )}
      {inviteDependents?.length !== maxNumDependents && canUserAddDependents && (
        <InviteDependentsForm
          className="space-y-3 bg-white px-4 md:px-5 py-5 md:rounded-lg md:border border-[#E6E6E6] mt-4"
          bottomActionButton={({ isDirty, isSubmitting }) => (
            <div className="mt-4 flex justify-end">
              <Button
                data-testid="dependent-send-invite-button"
                variant="primary"
                type="submit"
                disabled={!isDirty || isSubmitting}
                loading={isSubmitting}
              >
                Send invitation
              </Button>
            </div>
          )}
        />
      )}
      <RemoveDependentModal
        open={!!dependentToRemove}
        dependent={dependentToRemove}
        onClose={() => setDependentToRemove(undefined)}
      />
    </>
  );
};
