import React from 'react';
import { useState, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useHistory } from 'react-router-dom';
import {
  CreditCardIcon,
  DesktopHeader,
  DesktopHeaderProps,
  LogoutIcon,
  UserWithBorderIcon,
  SupportWithQuestionMarkIcon,
} from '@kindlyhuman/component-library';
import { useUser } from '../../hooks/useUser';
import { ROUTE_PATH } from '../../routes/route-paths';
import Toast from '../common/PopUpMessage';
import { LogoutModal } from '../LogoutModal';
import { SettingsModal, settingsModalAccordionItems } from '../settings_modal';
import { SupportModal } from '../SupportModal';
import { CALL_UNITS_CALL_DEFAULT_LENGTH } from '../scheduling-modal/scheduling-modal';
import { useSettingsModal } from '../../contexts/settings-modal';
import { useHasMyPrograms } from '../../hooks/useResources';

export interface DesktopMenuHeaderProps extends Omit<DesktopHeaderProps, 'menuItems' | 'avatarDropdownMenuItems'> {
  withoutMenuItems?: boolean;
}

export const DesktopMenuHeader = ({ withoutMenuItems, className, ...props }: DesktopMenuHeaderProps) => {
  const [isLogoutOpen, setLogoutOpen] = useState(false);
  const history = useHistory();
  const [isSupportModalOpen, setIsSupportModalOpen] = useState<boolean>(false);

  const { data: user } = useUser();
  const { isSettingModalOpen, initialSettingModalItem, openSettingModal, closeSettingModal } = useSettingsModal();

  const { hasMyPrograms, hasGroupResources } = useHasMyPrograms();

  const poweredByHeaderEnabled = user?.caller_role?.active_subscription?.package?.client?.powered_by_header_enabled;
  const poweredByHeader = user?.caller_role?.active_subscription?.package?.client?.powered_by_header_file_url;

  const isUnlimited = user?.onUnlimitedPlan;
  const availableMinutes = isUnlimited
    ? undefined
    : user?.caller_role.is_call_units
      ? user?.caller_role.payment_data.available_minutes / CALL_UNITS_CALL_DEFAULT_LENGTH
      : user?.caller_role.payment_data.available_minutes;

  const desktopMenuItems = useMemo(() => {
    const items = [
      {
        text: 'Home',
        href: ROUTE_PATH.HOME,
      },
      {
        text: 'My Connections',
        href: ROUTE_PATH.MY_CONNECTIONS,
      },
    ];
    if (hasMyPrograms) {
      items.splice(1, 0, {
        text: 'My Programs',
        href: ROUTE_PATH.RESOURCES,
      });
    }
    if (!hasGroupResources) {
      items.splice(2, 0, {
        text: 'Resources',
        href: ROUTE_PATH.GLOBAL_RESOURCES,
      });
    }
    return items;
  }, [hasMyPrograms, hasGroupResources]);

  const desktopAvatarDropdownMenuItems = useMemo(
    () => [
      {
        text: 'My profile',
        icon: <UserWithBorderIcon />,
        onClick: () => openSettingModal(settingsModalAccordionItems.myProfile),
      },
      {
        text: 'Membership',
        icon: <CreditCardIcon />,
        onClick: () => openSettingModal(settingsModalAccordionItems.myPlan),
      },
      {
        text: 'Support',
        icon: <SupportWithQuestionMarkIcon className="max-md:hidden" />,
        onClick: () => setIsSupportModalOpen(true),
      },
      {
        text: 'Log Out',
        icon: <LogoutIcon />,
        onClick: () => {
          setLogoutOpen(true);
        },
      },
    ],
    [],
  );

  return (
    <>
      <DesktopHeader
        className={twMerge('hidden md:block px-0 [&>div]:px-6', className)}
        menuItems={withoutMenuItems ? [] : desktopMenuItems}
        avatarDropdownMenuItems={withoutMenuItems ? [] : desktopAvatarDropdownMenuItems}
        userFirstName={user?.first_name}
        userLastName={user?.last_name}
        homeLink={ROUTE_PATH.HOME}
        onConnect={() => {
          history.push(ROUTE_PATH.AVAILABLE_LISTENERS);
        }}
        availableMinutes={availableMinutes}
        poweredByHeader={poweredByHeaderEnabled ? poweredByHeader : undefined}
        {...props}
      />
      <LogoutModal dataTestId="desktop-logout-dialog" open={isLogoutOpen} onClose={() => setLogoutOpen(false)} />
      <SettingsModal open={isSettingModalOpen} onClose={closeSettingModal} initialItem={initialSettingModalItem} />
      <SupportModal
        open={isSupportModalOpen}
        onClose={() => setIsSupportModalOpen(false)}
        onSuccessSubmitted={() => {
          Toast.success('Your support request has been successfully submitted', undefined);
        }}
      />
    </>
  );
};
