import { useRef, useState } from 'react';

import { Button, Dialog, DialogProps } from '@kindlyhuman/component-library';

import Toast from '../../components/common/PopUpMessage';

import { useUser } from '../../hooks/useUser';
import { Dependent, useInviteDependents } from '../../hooks/useInviteDependents';

export interface RemoveDependentModalProps extends DialogProps {
  dependent: Dependent | undefined;
}

export const RemoveDependentModal = ({ dependent, onClose, ...props }: RemoveDependentModalProps) => {
  const [inProgress, setInProgress] = useState<boolean>(false);

  const toastNotificationContainerRef = useRef<HTMLDivElement>(null);

  const { data: user } = useUser();
  const { removeDependent } = useInviteDependents(user?.id);

  const removeInviteDependent = (dependentId: number) => {
    setInProgress(true);

    return new Promise((res) =>
      removeDependent.mutate(dependentId, {
        onSuccess: () => {
          Toast.success(
            `You removed ${dependent?.first_name} ${dependent?.last_name}`,
            undefined,
            toastNotificationContainerRef?.current,
          );

          res(null);
          onClose();
        },
        onError: (error) => {
          // @ts-ignore
          const errorMessage = error?.response?.data?.description;

          if (errorMessage) {
            Toast.error(
              `There was an error removing your dependent. ${
                errorMessage ? errorMessage : 'Please contact your administrator.'
              }`,
              undefined,
              toastNotificationContainerRef?.current,
            );
          }

          res(error);
        },
        onSettled: () => setInProgress(false),
      }),
    );
  };

  return (
    <>
      <div ref={toastNotificationContainerRef} />
      <Dialog
        {...props}
        onClose={onClose}
        closeOnOutsideClick={true}
        className="rounded-2xl backdrop:bg-modalBackdropColor"
      >
        <div className="w-80 h-30 p-8 flex-col justify-center items-center gap-4 inline-flex">
          <div className="flex flex-col">
            <div className="self-stretch text-center text-gray-800 text-xl font-bold font-manrope leading-loose">
              Are you sure?
            </div>
            <div className="text-center text-stone-500 font-manrope">
              Are you sure you want to remove {dependent?.first_name} {dependent?.last_name}? They may not be able to
              access your benefit any more.
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => removeInviteDependent(dependent?.id ?? -1)}
            disabled={inProgress}
            loading={inProgress}
          >
            Remove
          </Button>
        </div>
      </Dialog>
    </>
  );
};
