import React from 'react';
import { FormError, Input, Select, Toggle } from '@kindlyhuman/component-library';
import { useFormContext } from 'react-hook-form';
import { useAppSettings } from '../../../hooks/useAppSettings';
import { debounce } from '@mui/material';

type phoneProps = {
  firstName?: string;
};

const PhoneNumber: React.FC<phoneProps> = ({ firstName }) => {
  const { data: appSettings } = useAppSettings();

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { onChange, ...rest } = register('mobile_phone');

  return (
    <div className="space-y-6">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          md:text-center md:text-3xl
        "
      >
        Hi {firstName} what's your <br className="md:hidden" />
        <span className="font-bold">phone number</span>?
      </p>
      <div>
        <div className="flex flex-col">
          <Input
            required
            {...rest}
            onChange={debounce(onChange, 750)}
            type="text"
            id="mobile_phone"
            label="PHONE NUMBER"
            onIconClick={() => {}}
            placeholder="(123) 456-7890"
            error={Boolean(errors?.mobile_phone?.message)}
          />
          {errors?.mobile_phone?.message && <FormError text={errors?.mobile_phone?.message as string} />}
        </div>
        <p className=" text-gray-900 text-sm not-italic font-normal leading-5 pt-1.5 md:text-neutral-700">
          We use your phone number when we're setting up a call with a peer. No peers will see your number at any time.
        </p>
      </div>
      <div>
        <div className="flex flex-col">
          <Select
            {...register('timezone')}
            label="YOUR TIME ZONE"
            isRequired
            className={errors?.supported_timezones?.message && 'border border-red'}
            options={
              appSettings?.supported_timezones.map((item) => ({
                value: item,
                label: item,
              })) ?? []
            }
          />
          <div className="text-gray-900 text-sm not-italic font-normal leading-5 pt-1.5 space-y-4 md:text-neutral-700">
            <p>
              We use your timezone when scheduling calls with our Peers. This should represent the timezone where you
              will normally be accessing the service.
            </p>
            <p>If the timezone shown above is incorrect, please select the correct timezone.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
