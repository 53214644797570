import { useHistory } from 'react-router-dom';

import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';

import { PLUSVirtualUrgentCare } from '../../../assets';
import getPartnerResourceConfig from '.././partner-resource-config';
import { Spinner } from '../../../components/common';
import { useExternalProviderPlan } from '../../../hooks/useExternalProviderPlans';
import { useUser } from '../../../hooks/useUser';
import { ResourceDetailView } from '.././resource-detail';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';

export const VirtualUrgentPage: React.FC = () => {
  const { data: user, isLoading: userLoading } = useUser();

  // TODO: add this line in to get the ssoLinkUrl
  // const { data: ssoLinkUrl, isLoading: ssoLinkUrlPending } = useTelemedRedirect(user?.id || 0);
  const ssoLinkUrl = { redirect_url: 'https://member.recurohealth.com' };

  const { data: providerPlans, isLoading: externalPlanLoading } = useExternalProviderPlan();
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;
  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);
  const navigate = useHistory();

  if (externalPlanLoading || userLoading) return <Spinner />;

  const name = '+Virtual Urgent Care';
  const description = '24/7 access to physicians for common medical concerns.';
  const longDescription = (
    <div className="flex flex-col gap-y-4">
      Your benefit includes 24/7 access to board-certified physicians for the treatment of common medical concerns.
      Visits are available for you and your family members virtually through your phone, web, and computer. If a
      prescription is written during your visit, it is immediately sent to your preferred pharmacy for easy pick up.
      <div>
        COMMON USES:
        <ul>
          <li>Allergies</li>
          <li>Cold and Flu</li>
          <li>Respiratory illnesses</li>
          <li>Pink eye</li>
          <li>UTIs</li>
          <li>Ear problems</li>
          <li>Skin Rashes</li>
          <li>And more</li>
        </ul>
      </div>
      <div>ONLINE ACCESS: Click the link above to schedule a visit online.</div>
      <div>
        PHONE ACCESS: Schedule a visit by phone by calling <div>855-6RECURO (855-673-2876)</div>
      </div>
    </div>
  );

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={name}
        url={providerPlan?.provider === 'recuro' ? 'https://member.recurohealth.com' : ssoLinkUrl?.redirect_url}
        urlDescription={''}
        phoneNumber={providerPlan?.provider === 'recuro' ? '8556732876' : undefined}
        ctaButtonText={'Connect Now'}
        imageUrl={PLUSVirtualUrgentCare}
        tagNames={['Urgent Care']}
        description={longDescription}
        clientLogoUrl={user?.caller_role.active_subscription?.package?.client?.comms_logo_file_url}
        partnerResourceConfig={partnerResourceConfig}
        recuro={providerPlan?.provider === 'recuro'}
      />
      <BackgroundStripe />
    </div>
  );
};
