import { Link } from 'react-router-dom';
import { MenuItem, SpinnerIcon } from '@kindlyhuman/component-library';
import { ROUTE_PATH } from '../../routes/route-paths';
import { MobileMenuHeader, SubHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { useUser } from '../../hooks/useUser';

export const MemberManagementPage = () => {
  return (
    <div className="flex flex-col gap-y-3 min-h-screen bg-neutral-700 bg-opacity-5">
      <DesktopMenuHeader />
      <MobileMenuHeader />
      <SubHeader title="Membership management" className="p-4" />
      <MenuItems />
    </div>
  );
};

const MenuItems: React.FC = () => {
  const { data: user, isLoading: isUserLoading } = useUser();

  if (isUserLoading) {
    return (
      <div className="w-full h-[100vh] flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <div>
      <Link to={ROUTE_PATH.PLAN}>
        <MenuItem className="bg-white" text="My Plan" icon={<PhoneIcon />} hasCaret />
      </Link>
      {!user?.onUnlimitedPlan && (
        <Link to={ROUTE_PATH.PAYMENT}>
          <MenuItem className="bg-white" text="Payment Method" icon={<CardIcon />} hasCaret />
        </Link>
      )}
      <Link to={ROUTE_PATH.CALL_HISTORY}>
        <MenuItem className="bg-white" text="Call History" icon={<ReceiptIcon />} hasCaret />
      </Link>
    </div>
  );
};

function CardIcon() {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.768 3.25h10.464c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 011.64 1.639c.226.444.32.924.365 1.47a16.431 16.431 0 01.042 1.277l.001.72V15.233c0 .813 0 1.469-.043 2-.045.546-.14 1.026-.366 1.47a3.75 3.75 0 01-1.639 1.64c-.444.226-.924.32-1.47.365-.531.043-1.187.043-2 .043H6.768c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 01-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2V8.768l.001-.723a.758.758 0 01.001-.101c.004-.448.014-.837.041-1.175.045-.547.14-1.027.366-1.471a3.75 3.75 0 011.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043zm14.444 3.64c.009.112.016.231.021.36H2.767c.005-.129.012-.248.021-.36.037-.453.107-.714.207-.911a2.25 2.25 0 01.984-.984c.197-.1.458-.17.912-.207.462-.037 1.057-.038 1.909-.038h10.4c.852 0 1.447 0 1.91.038.453.038.714.107.912.207.423.216.767.56.983.984.1.197.17.458.207.912zM2.75 8.8v-.05h18.5v6.45c0 .852 0 1.447-.038 1.91-.038.453-.107.714-.207.912a2.25 2.25 0 01-.983.983c-.198.1-.459.17-.913.207-.462.038-1.057.038-1.909.038H6.8c-.852 0-1.447 0-1.91-.038-.453-.037-.714-.107-.911-.207a2.25 2.25 0 01-.984-.983c-.1-.198-.17-.459-.207-.913-.037-.462-.038-1.057-.038-1.909V8.8zM6 11.25a.75.75 0 100 1.5h4a.75.75 0 000-1.5H6z"
        fill="#222833"
      />
    </svg>
  );
}

function ReceiptIcon() {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.624 2.351a.75.75 0 01.748-.002L6.5 4.136 9.628 2.35a.75.75 0 01.744 0L13.5 4.136l3.128-1.787A.75.75 0 0117.75 3v8.25H21a.75.75 0 01.75.75v7A2.75 2.75 0 0119 21.75H7.768c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 01-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2V3a.75.75 0 01.374-.649zM19 20.25c.69 0 1.25-.56 1.25-1.25v-6.25h-2.5V19c0 .69.56 1.25 1.25 1.25zm-2.45 0H7.8c-.852 0-1.447 0-1.91-.038-.453-.038-.714-.107-.911-.207a2.25 2.25 0 01-.984-.983c-.1-.198-.17-.459-.207-.913-.037-.462-.038-1.057-.038-1.909V4.292l2.378 1.36a.75.75 0 00.744 0L10 3.863l3.128 1.787a.75.75 0 00.744 0l2.378-1.359V19c0 .45.108.875.3 1.25zM6.25 9A.75.75 0 017 8.25h4a.75.75 0 010 1.5H7A.75.75 0 016.25 9zM10 12.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3zM9.25 17a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM8 13a1 1 0 11-2 0 1 1 0 012 0zm-1 5a1 1 0 100-2 1 1 0 000 2z"
        fill="#222833"
      />
    </svg>
  );
}

function PhoneIcon() {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.462 2.25a140.163 140.163 0 00.125 0c.332 0 .59 0 .83.042A2.75 2.75 0 018.45 3.88c.099.22.161.472.241.794l.015.058.535 2.141.027.107c.15.6.269 1.072.226 1.539a2.75 2.75 0 01-.378 1.161c-.24.403-.614.714-1.089 1.111l-.084.07-1.997 1.67a13.897 13.897 0 005.523 5.523l1.67-1.997.07-.084c.397-.476.708-.849 1.11-1.09a2.75 2.75 0 011.162-.377c.467-.043.939.075 1.54.226l.106.027 2.14.535.06.014c.321.08.572.143.793.242a2.75 2.75 0 011.588 2.034c.042.238.042.497.042.829v.125c0 .383 0 .686-.066.983-.242 1.084-1.246 1.995-2.348 2.13-.302.038-.562.012-.885-.02a38.491 38.491 0 00-.047-.004c-2.64-.258-5.016-.917-7.085-1.957-3.074-1.545-5.445-3.915-6.99-6.99-1.039-2.068-1.698-4.444-1.956-7.084l-.005-.047c-.031-.323-.057-.583-.02-.885.136-1.102 1.046-2.106 2.13-2.348.298-.066.6-.066.984-.066zm7.396 16.482c1.68.72 3.582 1.196 5.691 1.402.387.038.483.043.604.029.472-.059.963-.504 1.067-.968.026-.117.03-.244.03-.722 0-.42-.003-.537-.02-.629a1.25 1.25 0 00-.72-.924c-.086-.038-.198-.069-.606-.17l-2.14-.536c-.76-.19-.966-.231-1.147-.215a1.25 1.25 0 00-.528.172c-.155.093-.298.248-.8.848l-1.431 1.713zm-7.59-7.59l1.713-1.431c.6-.502.755-.645.848-.8A1.25 1.25 0 008 8.383c.016-.181-.025-.387-.215-1.146l-.535-2.141c-.102-.408-.133-.52-.171-.605a1.25 1.25 0 00-.924-.722c-.092-.016-.208-.019-.63-.019-.478 0-.604.004-.72.03-.465.104-.91.594-.969 1.067-.015.121-.009.217.029.604.206 2.11.682 4.01 1.402 5.691z"
        fill="#222833"
      />
    </svg>
  );
}
