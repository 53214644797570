import { useMemo, FunctionComponent, HTMLAttributes, ReactNode, useRef } from 'react';
import moment from 'moment';
import { useForm, Controller, SubmitHandler, FormState, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  FormItemName,
  Input,
  RadioButton,
  Select,
  FormError,
  DatePicker,
  SpinnerIcon,
} from '@kindlyhuman/component-library';

import { useUser } from '../../hooks/useUser';
import { useProfileTagGroups, TagGroup } from '../../hooks/useProfileTagGroups';
import { useAppSettings, useUserStateOptions } from '../../hooks/useAppSettings';

import { FormBlock } from '../common/form_block';
import Toast from '../../components/common/PopUpMessage';

import { generateDefaultValues, profileSchema, ProfileFormData } from './profile-validation';
import { INVALID_DATE } from '../../containers/onboarding/profile-step';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export interface ProfileFormProps extends HTMLAttributes<HTMLFormElement> {
  withLoader?: boolean;
  topActionButton?: (formState: FormState<FieldValues>) => ReactNode;
  bottomActionButton?: (formState: FormState<FieldValues>) => ReactNode;
  onSuccessSubmitted?: () => void;
  loaderComponent?: ReactNode;
}

const ProfileForm: FunctionComponent<ProfileFormProps> = ({
  topActionButton,
  bottomActionButton,
  withLoader = false,
  onSuccessSubmitted,
  loaderComponent,
  ...props
}): JSX.Element => {
  const toastNotificationContainerRef = useRef<HTMLDivElement>(null);
  const dfMdMedia = useMediaQuery('md');

  const { data: userDetails, isLoading: isUserLoading, updateUser } = useUser();
  const { data: formOptions, isLoading: isTagsLoading } = useProfileTagGroups();
  const { data: appSettings, isLoading: isAppLoading } = useAppSettings();
  const { data: userStateOptions, isLoading: isStateOptionsLoading } = useUserStateOptions();

  const loading = isUserLoading || isTagsLoading || isAppLoading || isStateOptionsLoading;
  const groupId = userDetails?.caller_role.active_subscription?.package.code || null;
  const preferNotToSay = 'I prefer not to say';

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty, isSubmitting, isValid, ...formState },
    reset,
    watch,
  } = useForm({
    defaultValues: useMemo(() => {
      const profileFields = ['gender', 'pronoun'];
      profileFields.forEach((field) => {
        if (userDetails?.[field] === null) {
          userDetails[field] = preferNotToSay;
        }
      });

      return generateDefaultValues(userDetails);
    }, [userDetails]),
    resolver: yupResolver(profileSchema(userDetails, groupId)),
    mode: 'onBlur',
  });

  /**
   * We have the following problem - a common form to change something + different buttons how do we submit this form
   * There are two ways to solve this problem:
   *  1. Pass the function to the form. The form calls this function with the form state parameters. The function returns JSX
   *  2. We will get the state of the form via ref. We send the form through the methods we added in ref
   * I chose the first option because it is more intuitive and consistent than the second
   */
  const { topActionButtonElement, bottomActionButtonElement } = useMemo(
    () => ({
      topActionButtonElement: topActionButton
        ? topActionButton({ errors, isDirty, isSubmitting, isValid, ...formState })
        : null,
      bottomActionButtonElement: bottomActionButton
        ? bottomActionButton({ errors, isDirty, isSubmitting, isValid, ...formState })
        : null,
    }),
    [topActionButton, bottomActionButton, errors, isDirty, isSubmitting, isValid, formState],
  );
  const supportedTimezonesOptions = useMemo(
    () =>
      appSettings?.supported_timezones?.map((item) => ({
        label: item,
        value: item,
      })),
    [appSettings?.supported_timezones],
  );
  const { genderOptionsList, pronounOptionsList } = useMemo(
    () => ({
      genderOptionsList: getFormOptions('GENDER', formOptions),
      pronounOptionsList: getFormOptions('PRONOUN', formOptions),
    }),
    [formOptions],
  );

  const submit: SubmitHandler<Partial<ProfileFormData>> = async (data) => {
    data.gender = data.gender === preferNotToSay ? null : data.gender;
    data.pronoun = data.pronoun === preferNotToSay ? null : data.pronoun;

    const { data: newUserData } = await updateUser.mutateAsync({
      ...userDetails,
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,

      pronoun: data.pronoun,
      street_address_1: data.street_address_1,
      street_address_2: data.street_address_2,
      city: data.city,
      state: data.state,
      zip_code: data.zip_code,
      mobile_phone: data.mobile_phone,
      email_address: data.email_address,
      timezone: data.timezone,
      date_of_birth: data.date_of_birth,
      is_text_compatible_phone: data.is_text_compatible_phone,
    });

    reset(generateDefaultValues(newUserData));

    Toast.success('Profile updated successfully', undefined, toastNotificationContainerRef?.current);

    if (onSuccessSubmitted) {
      onSuccessSubmitted();
    }
  };

  if (loading && withLoader) {
    return loaderComponent ? (
      <>{loaderComponent}</>
    ) : (
      <div className="w-full h-full flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(submit)} {...props}>
      <div ref={toastNotificationContainerRef} />
      <div className="w-full flex flex-col min-h-screen">
        <div className="w-full h-full">
          <div className={`${dfMdMedia && 'p-6 pb-0 bg-whiteSmoke'} sticky top-0 z-10`}>{topActionButtonElement}</div>
          <div className={`${dfMdMedia && 'p-6 pt-0 space-y-5 md:space-y-4'}`}>
            <FormBlock header="Personal Details">
              <Input
                label="FIRST NAME"
                type="text"
                placeholder="First Name"
                required={true}
                {...register('first_name')}
                onIconClick={() => {}}
                id="first_name"
                error={Boolean(errors?.first_name?.message)}
              />
              {errors?.first_name?.message && <FormError text={errors?.first_name?.message as string} />}
              <Input
                label="LAST NAME"
                type="text"
                placeholder="Last Name"
                required={true}
                {...register('last_name')}
                onIconClick={() => {}}
                id="last_name"
                error={Boolean(errors?.last_name?.message)}
              />
              {errors?.last_name?.message && <FormError text={errors?.last_name?.message as string} />}
              <div>
                <FormItemName name="DATE OF BIRTH" isRequired className="mb-[6px]" />
                {userDetails && (
                  <Controller
                    name="date_of_birth"
                    control={control}
                    defaultValue={
                      userDetails?.date_of_birth === '0001-01-01'
                        ? ''
                        : moment(userDetails?.date_of_birth ?? '')
                            ?.format('MM-DD-yyyy')
                            .toString()
                    }
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <DatePicker
                        value={value?.replaceAll('-', '/')}
                        onChange={(value: any | null) => {
                          if (value) {
                            const date =
                              +value?.format('yyyy') < 1000 || !value?.year()
                                ? INVALID_DATE
                                : value?.format('MM-DD-yyyy').toString();
                            return onChange(date);
                          } else {
                            setValue('date_of_birth', '', {
                              shouldDirty: true,
                            });
                          }
                        }}
                        onOpen={() => {
                          if (value === '') {
                            return onChange('01-01-1990');
                          }
                        }}
                      />
                    )}
                  />
                )}
                {errors?.date_of_birth?.message && (
                  <FormError text={errors?.date_of_birth?.message as string} className="mt-3" />
                )}
              </div>
            </FormBlock>
            <FormBlock header="Contact Details">
              <Input
                label="PHONE NUMBER"
                type="text"
                required
                placeholder="(123) 456-7890"
                {...register('mobile_phone')}
                id="mobile_phone"
                onIconClick={() => {}}
                error={Boolean(errors?.mobile_phone?.message)}
              />
              {errors?.mobile_phone?.message && <FormError text={errors?.mobile_phone?.message as string} />}
              <Input
                label="EMAIL ADDRESS"
                type="email"
                required
                placeholder="Your email"
                {...register('email_address')}
                id="email_address"
                onIconClick={() => {}}
                error={Boolean(errors?.email_address?.message)}
                disabled={!userDetails?.caller_role?.active_subscription?.package?.client.member_self_enrollment}
              />
              {errors?.email_address?.message && <FormError text={errors?.email_address?.message as string} />}
            </FormBlock>
            <FormBlock header="Receive texts">
              <div className="flex-col justify-start items-start gap-5 flex">
                <div className="justify-start items-start gap-4 inline-flex">
                  <RadioButton
                    data-testid="toggle-phone-radio"
                    variant={'DEFAULT'}
                    checked={watch('is_text_compatible_phone') === true}
                    onChange={(checked) => {
                      setValue('is_text_compatible_phone', true, { shouldDirty: true });
                    }}
                  />
                  <div className="grow shrink basis-0 text-gray-800 text-base font-normal leading-normal">
                    Yes, send me texts from Kindly Human
                  </div>
                </div>
                <div className="justify-start items-start gap-4 inline-flex">
                  <RadioButton
                    variant={'DEFAULT'}
                    checked={watch('is_text_compatible_phone') === false}
                    onChange={(checked) => setValue('is_text_compatible_phone', false, { shouldDirty: true })}
                  />
                  <div className="grow shrink basis-0 text-gray-800 text-base font-normal leading-normal">No</div>
                </div>
              </div>
            </FormBlock>
            <FormBlock header="Location">
              <Input
                className="col-span-2"
                label="ADDRESS"
                type="text"
                placeholder="Address"
                required={true}
                {...register('street_address_1')}
                id="street_address_1"
                onIconClick={() => {}}
                error={Boolean(errors?.street_address_1?.message)}
              />
              {errors?.street_address_1?.message && <FormError text={errors?.street_address_1?.message as string} />}
              <Input
                className="col-span-2"
                label="ADDRESS 2"
                type="text"
                placeholder="Address 2"
                {...register('street_address_2')}
                id="street_address_2"
                onIconClick={() => {}}
                error={Boolean(errors?.street_address_2?.message)}
              />
              {errors?.street_address_2?.message && <FormError text={errors?.street_address_2?.message as string} />}
              <Input
                label="CITY"
                type="text"
                placeholder="City"
                required={true}
                {...register('city')}
                id="city"
                onIconClick={() => {}}
                error={Boolean(errors?.city?.message)}
              />
              {errors?.city?.message && <FormError text={errors?.city?.message as string} />}
              <div>
                <Select
                  {...register('state')}
                  label="STATE"
                  isRequired
                  className={errors?.state?.message && 'border border-red'}
                  options={userStateOptions ?? []}
                />
                {errors?.state?.message && <FormError text={errors?.state?.message as string} className="mt-3" />}
              </div>
              <Input
                label="ZIP CODE"
                type="number"
                placeholder="Zip Code"
                required={true}
                {...register('zip_code')}
                id="zip_code"
                onIconClick={() => {}}
                error={Boolean(errors?.zip_code?.message)}
              />
              {errors?.zip_code?.message && <FormError text={errors?.zip_code?.message as string} />}
              <div>
                <Select
                  {...register('timezone')}
                  label="TIME ZONE"
                  isRequired
                  className={errors?.timezone?.message && 'border border-red'}
                  options={supportedTimezonesOptions ?? []}
                />
                {errors?.timezone?.message && <FormError text={errors?.timezone?.message as string} className="mt-3" />}
              </div>
            </FormBlock>
            <FormBlock header="Identity">
              <div className="col-span-2">
                <Select
                  {...register('gender')}
                  label="Gender"
                  isRequired
                  className={errors?.gender?.message && 'border border-red'}
                  options={genderOptionsList ?? []}
                />
                {errors?.gender?.message && <FormError text={errors?.gender?.message as string} className="mt-3" />}
              </div>
              <div className="col-span-2">
                <Select
                  {...register('pronoun')}
                  label="Pronouns"
                  isRequired
                  className={errors?.pronoun?.message && 'border border-red'}
                  options={pronounOptionsList ?? []}
                />
                {errors?.pronoun?.message && <FormError text={errors?.pronoun?.message as string} className="mt-3" />}
              </div>
            </FormBlock>
          </div>
          {bottomActionButtonElement}
        </div>
      </div>
    </form>
  );

  function getFormOptions(key: string, formDataOptions: TagGroup[] | undefined = []) {
    return formDataOptions
      ?.find((item: any) => item.key === key)
      ?.tags?.map(({ name }) => ({
        value: name,
        label: name,
        name,
      }))
      .concat({
        value: preferNotToSay,
        label: preferNotToSay,
        name: preferNotToSay,
      });
  }
};

export default ProfileForm;
