import {
  Button,
  CircledArrowRightIcon,
  Dialog,
  EmailIcon,
  EyeIcon,
  MobileHeader,
  Input,
  LockIcon,
  Checkbox,
} from '@kindlyhuman/component-library';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { useEffect, useRef, useState } from 'react';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import { AxiosError } from 'axios';
import { useUser } from '../../hooks/useUser';
import Toast from '../../components/common/PopUpMessage';
import { Features, useFeatureFlag } from '../../hooks/useFeatureFlag';
import useAuth from '../../hooks/useAuth';
import { Spinner } from '../../components/common';

export const LoginPage = (): JSX.Element => {
  const history = useHistory();
  const [isTrusted, setIsTrusted] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const trustRef = useRef<HTMLInputElement>(null);
  const forgotPasswordEmailRef = useRef<HTMLInputElement>(null);
  const { search } = useLocation();
  const { authenticate, passwordReset } = useUser();
  const { keycloak, authToken } = useAuth();
  const keycloakFeatureFlag = useFeatureFlag(Features.MWA_KEYCLOAK_LOGIN);
  const [rerender, setRerender] = useState<boolean>(false);

  // We check for the next query param (generated in privateRoute) in the URL to redirect the user to the page they were trying to access before logging in.
  const next = new URLSearchParams(search).get('next');

  useEffect(() => {
    setRerender((prev) => !prev);
  }, [keycloakFeatureFlag.enabled]);

  useEffect(() => {
    /**
     * When the user lands on the login page, if Keycloak is enabled, we forward them to Keycloak to login and set `storedRedirect`.
     * This way, if they hit back from Keycloak and land on the login page, we see that they were already redirected to Keycloak and instead redirect them to the landing page.
     * This prevents the user from being stuck in a loop of login -> Keycloak -> login -> Keycloak.
     *
     * If the user came from Keycloak, typed in the MWA URL, and clicks on login,
     * we don't want to redirect them to Keycloak again if `storedRedirect` is still true, so we set `cameFromLandingPage` when they click that link.
     */
    const storedRedirect = sessionStorage.getItem('redirectedToKeycloak');
    const cameFromLandingPage = sessionStorage.getItem('cameFromLandingPage');

    if (keycloakFeatureFlag.enabled && !!keycloak && !authToken) {
      if (storedRedirect && !cameFromLandingPage) {
        sessionStorage.removeItem('redirectedToKeycloak');
        history.push(ROUTE_PATH.LANDING_PAGE);
      } else {
        sessionStorage.setItem('redirectedToKeycloak', 'true');
        keycloak.login();
      }
      sessionStorage.removeItem('cameFromLandingPage');
    }
  }, [keycloak, authToken, keycloakFeatureFlag.enabled, history, rerender]);

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    authenticate.mutate({
      email_address: emailRef.current!.value,
      password: passwordRef.current!.value,
      trusted: Boolean(trustRef.current!.checked),
      setDisabled,
      next,
    });
  };

  const handlePasswordReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    passwordReset
      .mutateAsync({
        email_address: forgotPasswordEmailRef.current!.value,
      })
      .then(() => {
        setShowDialog(false);
        Toast.success('Password reset email sent.');
      })
      .catch((err: AxiosError<any>) => {
        setShowDialog(false);
        Toast.error(err.response?.data.description);
      })
      .finally(() => setDisabled(false));
  };

  if (keycloakFeatureFlag.isLoading || keycloakFeatureFlag.enabled !== false) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen bg-[#F6F6F6]">
      <MobileHeader className="md:hidden" />
      <div className="grid w-screen grid-cols-1 md:grid-cols-2 md:min-h-screen">
        <WelcomeSlogan className="hidden md:block" />
        <div
          className="
            p-5 flex-col items-center justify-center w-full
            md:mx-auto md:max-w-200 md:px-10 lg:px-24 md:flex
          "
        >
          <div className="flex mt-8 mb-8 flex-col self-stretch gap-1">
            <h2 className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">Welcome back</h2>
            <p className="text-gray-900 text-base not-italic font-normal leading-6">Enter your details below</p>
          </div>

          <form className="w-full" onSubmit={handleLogin}>
            <Input
              id="email"
              ref={emailRef}
              required={true}
              label="Email Address"
              autoComplete="on"
              placeholder="Enter your email"
              type="email"
              startIcon={<EmailIcon />}
              onIconClick={() => {}}
            />
            <Input
              id="password"
              ref={passwordRef}
              className="mt-4"
              required={true}
              label="Password"
              autoComplete="current-password"
              placeholder="Enter your password"
              type={showPassword ? 'text' : 'password'}
              endIcon={<EyeIcon />}
              startIcon={<LockIcon />}
              onIconClick={() => setShowPassword(!showPassword)}
            />
            <div className="flex items-center justify-between mb-8 my-4">
              <label className="flex gap-x-2 cursor-pointer">
                <Checkbox
                  ref={trustRef}
                  variant="DEFAULT"
                  className="mr-0"
                  checked={isTrusted}
                  onChange={() => setIsTrusted((prev) => !prev)}
                />
                <div className="text-gray-900 text-base">Trust this device</div>
              </label>
              <div className="flex justify-end gap-1">
                <p
                  className="text-right text-sm not-italic font-bold leading-5 text-purple-900 cursor-pointer"
                  onClick={() => {
                    setShowDialog(true);
                  }}
                >
                  Forgot password?
                  <CircledArrowRightIcon className="inline-block ml-1 align-top" />
                </p>
              </div>
            </div>
            <div className="flex">
              <Button variant="primary" className="w-full" disabled={disabled}>
                Sign In
              </Button>
            </div>
          </form>

          <div className="flex justify-center py-3.5 gap-1 mt-6" onClick={() => {}}>
            <p className="text-sm not-italic font-normal leading-5 font-manrope">Don&rsquo;t have an account?</p>
            <Link
              to={ROUTE_PATH.WELCOME_PAGE}
              className="text-center text-sm not-italic font-bold leading-5 text-purple-900"
            >
              Create now
              <CircledArrowRightIcon className="ml-1 inline-block align-top" />
            </Link>
          </div>

          <Dialog
            className="max-w-screen-xsm rounded-lg w-full shadow-modal bg-white backdrop:bg-modalBackdropColor"
            onClose={() => setShowDialog(false)}
            open={showDialog}
            closeOnOutsideClick={true}
          >
            <div className="m-4 space-y-1 md:space-y-4">
              <div>
                <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                  Forgot your password?
                </p>
                <p className="text-gray-900 text-base not-italic font-normal leading-6">
                  Please provide your email so we can send you a secure link in order to reset your password.
                </p>
              </div>
              <form className="space-y-4" onSubmit={handlePasswordReset}>
                <Input
                  ref={forgotPasswordEmailRef}
                  required={true}
                  label="Email Address"
                  placeholder="Enter your email"
                  type="email"
                  startIcon={<EmailIcon />}
                  onIconClick={() => {}}
                />
                <div className="flex flex-col gap-2 md:mt-8 md:flex-row-reverse md:justify-around">
                  <Button variant="primary" className="w-full md:w-auto" disabled={disabled}>
                    Send
                  </Button>
                  <Button
                    type="reset"
                    variant="secondary"
                    className="w-full md:w-auto"
                    onClick={() => setShowDialog(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
