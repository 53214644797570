import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAllCalls } from '../../hooks/useCalls';
import { useUser } from '../../hooks/useUser';

import { MobileMenuHeader, SubHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { HistoryCallCard, HistoryCallCardSkeleton } from '../../components/common/call_history/HistoryCallCard';

export const CallHistory = () => {
  const navigate = useHistory();

  return (
    <div className="flex flex-col gap-y-3 min-h-screen bg-neutral-700 bg-opacity-5">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <SubHeader title="Call history" className="p-4" />
      <CallItems />
    </div>
  );
};

const NoCallsCard = () => (
  <div className="bg-white p-28 w-full border-t border-b border-neutral-200 flex-col justify-start items-start gap-1 inline-flex">
    <div className="text-center self-center text-gray-800 text-lg font-bold leading-tight">No calls here yet</div>
  </div>
);

const CallItems: React.FC = () => {
  const { data: user } = useUser();

  const { isLoading, data: calls } = useAllCalls(user?.caller_role_id);

  if (isLoading) {
    return (
      <div className="space-y-2">
        {[...Array(5).keys()].map((index) => (
          <div key={index} className="px-4 py-5 bg-white border-t border-b border-neutral-200">
            <HistoryCallCardSkeleton />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {calls && calls.length > 0 ? (
        calls.map((call) => (
          <div key={call.id} className="px-4 py-5 bg-white border-t border-b border-neutral-200">
            <HistoryCallCard call={call} />
          </div>
        ))
      ) : (
        <NoCallsCard />
      )}
    </div>
  );
};
