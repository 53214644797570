import React from 'react';
import { useHistory } from 'react-router';
import { Peer } from '../../hooks/usePeers';
import { ROUTE_PATH } from '../../routes/route-paths';
import { Avatar, AvatarSkeleton, PhoneIcon } from '@kindlyhuman/component-library';
import { Clock } from '../../assets';
import { useUser } from '../../hooks/useUser';
import { TagPill } from './tag-pill';

export const MyConnectionsPeerTile: React.FC<{ peer: Peer }> = ({ peer }) => {
  const history = useHistory();
  const { data: user } = useUser();

  const showClientAffiliation = user?.client_id === peer.client_id && peer.client_image_url;
  const showResourceAffiliation = user?.client_id === peer.resource_client_id && peer.resource_image_url;

  return (
    <div
      key={peer.listener_role_id}
      className="bg-white rounded-[10px] h-[80px] flex select-none cursor-pointer"
      onClick={() => {
        history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${peer.listener_role_id}`);
      }}
    >
      <Avatar variant="rounderSmall" image={peer.profile_photo_url_square} className="m-3" />
      <div className="flex flex-col justify-center w-full">
        <div className="flex justify-between">
          <div className="flex flex-col py-2 gap-2">
            <div
              data-testid={`peer-${peer.display_name}`}
              className="text-lg font-textaBlack antialiased leading-normal"
            >
              {peer.display_name}
            </div>
            <div className="flex gap-2 h-5">
              {peer.is_listener && (
                <>
                  <div className="rounded-full w-5 h-5 bg-[#2ee5da] flex justify-center items-center">
                    <PhoneIcon color="black" width={11} />
                  </div>
                  <div className="text-sm font-texta antialiased leading-normal">Listener</div>
                </>
              )}
              {peer.is_available && peer.is_listener && (
                <div className="flex gap-2">
                  <div className="rounded-full w-5 h-5 bg-[#2ee5da] flex justify-center items-center">
                    <img src={Clock} alt="clock" />
                  </div>
                  <div className="text-sm font-texta antialiased leading-normal text-nowrap">Available Now</div>
                </div>
              )}
            </div>
          </div>
          <div>
            {(showResourceAffiliation || showClientAffiliation) && (
              <div className="m-1.5">
                {showResourceAffiliation ? (
                  <img src={peer.resource_image_url} className="w-16" alt="Experience Resource Logo" />
                ) : (
                  <img src={peer.client_image_url} className="w-16" alt="Experience Client Logo" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MyConnectionsPeerTileSkeleton: React.FC = () => {
  return (
    <div className="bg-gray-200 rounded-[10px] h-[80px] flex gap-2 animate-pulse">
      <AvatarSkeleton variant="rounderSmall" className="m-3" />
      <div className="flex flex-col py-3 gap-2 w-full">
        <div className="h-6 bg-gray-300 rounded w-3/4"></div>
        <div className="flex gap-1 w-full">
          <div className="flex gap-2 w-1/2">
            <div className="rounded-full w-5 h-5 bg-gray-300"></div>
            <div className="h-4 bg-gray-300 rounded w-full"></div>
          </div>
          <div className="flex gap-2 w-1/2">
            <div className="rounded-full w-5 h-5 bg-gray-300"></div>
            <div className="h-4 bg-gray-300 rounded w-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
