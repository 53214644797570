import React from 'react';
import { useExternalProviderPlan, useCounselingRedirect } from '../../../hooks/useExternalProviderPlans';
import { useUser } from '../../../hooks/useUser';
import { Spinner } from '../../../components/common';
import getPartnerResourceConfig from '.././partner-resource-config';
import { ResourceDetailView } from '.././resource-detail';
import { CounselingDetailPng, DialCare, PLUSVirtualBehavioralHealth, Walmart } from '../../../assets';
import { DesktopMenuHeader, MobileMenuHeader } from '../../../components/MenuHeader';
import { useHistory } from 'react-router-dom';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';

const getCounselingProviderData = (provider?: string) => {
  if (provider === 'dialcare') {
    return {
      name: '+Counseling',
      description: 'Your benefit includes the opportunity to speak with a licensed counselor.',
      longDescription: `
      Through our partnership with Dialcare, you have access to licensed clinical
      counselors to discuss more serious issues.
    `,
      providerText: 'THIS PLAN IS NOT INSURANCE and is not intended to replace health insurance.',
      providerImage: <img className="min-w-full" src={DialCare} alt="DialCare" />,
      bannerImage: CounselingDetailPng,
    };
  } else if (provider === 'me_md') {
    return {
      name: '+Counseling',
      description: 'Your benefit includes the opportunity to speak with a licensed counselor.',
      longDescription: `
      Through our partnership with MeMD, you have access to licensed clinical
      counselors to discuss more serious issues.
    `,
      providerText: `
      Walmart Health is comprised of independent physician practices. Walmart Health
      Virtual Care (WHVC) offers talk therapy services via telehealth to patients
      nationwide. Telehealth services may vary by state. Telehealth services are
      provided in accordance with state law by licensed health care professionals,
      subject to the licensed professionals’ judgment. Talk therapy providers do not
      write prescriptions. WHVC is not an insurance product.
    `,
      providerImage: (
        <div className="w-80 h-48 p-8 bg-[#F6F6F6] rounded-lg shadow border border-neutral-200 grid place-items-center">
          <img className="min-w-full mb-3" src={Walmart} alt="Walmart Health Virtual Care" />
        </div>
      ),
      bannerImage: CounselingDetailPng,
    };
  } else {
    return {
      name: '+Virtual Behavioral Health',
      description: 'Access to licensed counselors, therapists, and psychiatrists.',
      longDescription: (
        <div className="flex flex-col gap-y-4">
          Your benefit includes virtual access for you and your family to connect with licensed counselors, therapists,
          and psychiatrists for ongoing conditions. Visits are available within 48 hours.
          <div>
            COMMON USES:
            <ul>
              <li>Grief and loss</li>
              <li>Addiction</li>
              <li>Depression</li>
              <li>Anxiety</li>
              <li>Stress</li>
              <li>And more</li>
            </ul>
          </div>
          <div>ONLINE ACCESS: Click the link above to schedule a visit online.</div>
          <div>
            PHONE ACCESS: Schedule a visit by phone by calling <div>855-6RECURO (855-673-2876)</div>
          </div>
        </div>
      ),
      bannerImage: PLUSVirtualBehavioralHealth,
    };
  }
};

const Counseling: React.FunctionComponent = (): JSX.Element => {
  const navigate = useHistory();

  const { data: user, isLoading: userLoading } = useUser();
  const { data: providerPlans, isLoading: externalPlanLoading } = useExternalProviderPlan();
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;

  const { data: ssoLinkUrl, isLoading: ssoLinkUrlPending } = useCounselingRedirect(user?.id || 0);

  if (externalPlanLoading || userLoading) return <Spinner />;

  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);

  const providerData = getCounselingProviderData(providerPlan?.provider);

  return (
    <div className="md:bg-neutral-700 md:bg-opacity-5 h-screen md:pb-8 flex flex-col">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={providerData.name}
        phoneNumber={providerPlan?.provider === 'recuro' ? '8556732876' : undefined}
        url={providerPlan?.provider === 'recuro' ? 'https://member.recurohealth.com' : ssoLinkUrl?.redirect_url}
        urlDescription={''}
        ctaButtonText={'Connect Now'}
        ctaButtonDisabled={ssoLinkUrlPending}
        imageUrl={providerPlan?.provider === 'recuro' ? PLUSVirtualBehavioralHealth : CounselingDetailPng}
        tagNames={['Mental Health']}
        description={providerData.longDescription}
        clientLogoUrl={user?.caller_role.active_subscription?.package?.client?.comms_logo_file_url}
        partnerResourceConfig={partnerResourceConfig}
        ssoLinkUrlPending={ssoLinkUrlPending}
        recuro={providerPlan?.provider === 'recuro'}
      />
      <BackgroundStripe />
    </div>
  );
};

export default Counseling;
