import { useHistory } from 'react-router-dom';

import { useResources } from '../../hooks/useResources';
import { useProviderPlan } from '../../hooks/useExternalProviderPlans';

import {
  TelemedDetailPng,
  CounselingDetailPng,
  PLUSCare,
  PLUSCounseling,
  PLUSVirtualPrimaryCare,
  PLUSVirtualUrgentCare,
  PLUSPrescriptionBenefit,
  PLUSPediatric,
} from '../../assets';

import { ROUTE_PATH } from '../../routes/route-paths';

import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { Spinner } from '../../components/common';
import { useUser } from '../../hooks/useUser';
import { ResourceCard, ResourceCardProps } from './resource-components/resource-card';
import { useMemo } from 'react';
import { BackgroundStripe } from '../../components/mwa-3.5-redesign/bg-stripe';

export const ResourcePage = () => {
  const { data: user, isLoading: userLoading } = useUser();
  const { data: groupLevelResources, isLoading: groupLevelResourcesLoading } = useResources({
    clientId: user?.client_id,
  });
  const { providerPlan, isLoading: providerPlansLoading } = useProviderPlan();

  const navigate = useHistory();

  const providerResources = useMemo(() => {
    const items = [];

    if (providerPlan?.provider_types.includes('primary')) {
      items.push({
        link: ROUTE_PATH.TELEMED,
        name: providerPlan.provider === 'recuro' ? '+Virtual Primary Care' : '+Telemed',
        description:
          providerPlan.provider === 'recuro'
            ? 'Find out more about +Virtual Primary Care today'
            : 'Find out more about +Telemed today.',
        image_url: providerPlan.provider === 'recuro' ? PLUSVirtualPrimaryCare : TelemedDetailPng,
        tag_names: ['Virtual Care'],
      });
    }

    if (providerPlan?.provider_types.includes('counseling')) {
      items.push({
        link: ROUTE_PATH.COUNSELING,
        name: '+Counseling',
        description: 'Find out more about +Counseling now',
        image_url: providerPlan.provider === 'recuro' ? PLUSCounseling : CounselingDetailPng,
        tag_names: ['Mental Health'],
      });
    }

    if (providerPlan?.provider_types.includes('crisis')) {
      items.push({
        link: ROUTE_PATH.CRISIS_LITE,
        name: '+Care',
        description: 'Find out more about +Care now',
        image_url: PLUSCare,
        tag_names: ['Primary Care'],
      });
    }

    if (providerPlan?.provider_types.includes('urgent')) {
      items.push({
        link: ROUTE_PATH.URGENT,
        name: '+Virtual Urgent Care',
        description: 'Find out more about Urgent Care now',
        image_url: PLUSVirtualUrgentCare,
        tag_names: ['Urgent Care'],
      });
    }

    if (providerPlan?.provider_types.includes('rx')) {
      items.push({
        link: ROUTE_PATH.PRESCRIPTION,
        name: '+Prescription Benefit',
        description: 'Medications for Less',
        image_url: PLUSPrescriptionBenefit,
        tag_names: ['Prescription Medications'],
      });
    }
    if (providerPlan?.provider_types.includes('pediatric')) {
      items.push({
        link: ROUTE_PATH.PEDIATRIC,
        name: '+Pediatric Behavioral Health',
        description: 'Comprehensive mental wellness support for children and families',
        image_url: PLUSPediatric,
        tag_names: ['Behavioral Health'],
      });
    }
    return items satisfies Partial<ResourceCardProps>[];
  }, [providerPlan]);

  const displayedResources = groupLevelResources?.data;

  if (providerPlansLoading || groupLevelResourcesLoading || userLoading) return <Spinner />;

  return (
    <>
      <div className="w-full h-screen flex flex-col overflow-hidden bg-white bg-opacity-5">
        <DesktopMenuHeader />
        <MobileMenuHeader onBack={() => navigate.goBack()} />
        <div className="w-full overflow-y-auto">
          <div className="w-full mx-auto py-5 md:w-auto md:max-w-7xl md:px-6">
            <div className="flex flex-col items-start md:flex-row md:items-center justify-between px-4 pb-7 space-y-2 md:px-0 md:pb-8">
              <p className="text-[#222833] text-2xl font-semibold font-['Texta'] leading-normal flex flex-nowrap">
                My Programs
              </p>
              <div className="flex flex-row text-[#222833] text-base font-bold font-['Manrope'] leading-[21px] gap-4">
                <div className="my-auto">Part of My Plan</div>
                <img
                  className="w-[98px] h-[49px]"
                  src={user?.caller_role.active_subscription?.package.client.comms_logo_file_url}
                />
              </div>
            </div>
            <div className="mx-auto justify-center grid gap-4 md:grid-cols-2 lg:grid-cols-3 md:gap-6">
              {providerPlan && providerResources?.map((resource) => <ResourceCard key={resource.name} {...resource} />)}
              {displayedResources?.map((resource) => (
                <ResourceCard
                  key={`${ROUTE_PATH.RESOURCE}/${resource.id}_${resource.name.replaceAll(' ', '-')}`}
                  {...resource}
                  link={`${ROUTE_PATH.RESOURCE}/${resource.id}_${resource.name.replaceAll(' ', '-')}`}
                />
              ))}
            </div>
          </div>
        </div>
        <BackgroundStripe />
      </div>
    </>
  );
};
