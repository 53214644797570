import React, { FunctionComponent, useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckmarkIcon,
  CircledArrowRightIcon,
  DesktopHeader,
  MobileHeader,
  SpinnerIcon,
} from '@kindlyhuman/component-library';

import { twMerge } from 'tailwind-merge';
import { useSubscriptions } from '../../../hooks/useSubscription';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { useHistory } from 'react-router-dom';
import { SubHeader } from '../../../components/MenuHeader';
import { UserPlanBenefits } from '../../../components/common/user_plan';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface PaymentPlanProps {
  className?: string;
  banner?: string;
  minutes?: number;
  planPrice: number;
  discountPercentage?: number;
  planDescription?: string;
  planDetails?: string;
  selected?: boolean;
  onSelect?: (code: number) => void;
  code?: string;
}

export const PaymentPlan: FunctionComponent<
  PaymentPlanProps & { selected: boolean; onSelect: (code: any) => void; code: any }
> = ({
  className,
  banner,
  minutes,
  planPrice,
  discountPercentage,
  planDescription,
  planDetails,
  selected,
  onSelect,
  code,
}) => {
  const dfMdMedia = useMediaQuery('md');

  return (
    <div
      data-testid={`plan-${planDescription}`}
      className={twMerge('space-y-4', !dfMdMedia && selected ? 'bg-[#E6E6E6]' : 'bg-white', className)}
    >
      <div className="text-gray-800 font-medium space-y-2">
        {banner && (
          <div className="w-screen -translate-x-1/2 relative left-1/2 md:w-full md:left-0 md:translate-x-0">
            <p
              className="
                px-4 py-1 bg-[#AFFF54] bg-opacity-30 rounded-tr-lg rounded-br-lg text-sm text-center inline-block
                md:rounded-lg
              "
            >
              {banner}
            </p>
          </div>
        )}
        <div className="flex justify-between">
          <p className="text-base">{minutes} minutes</p>
          <div className="md:hidden">
            <Checkbox
              data-testid={`plan-checkbox-${planDescription}`}
              className="mr-0"
              variant="DEFAULT"
              checked={selected}
              onChange={() => onSelect(code)}
            />
          </div>
        </div>
        <p className="text-xl font-bold md:text-2xl">${planPrice} / month</p>
        <p className="text-sm">{planDescription}</p>
      </div>
      {!!discountPercentage && (
        <div className="flex items-center gap-2">
          <CheckmarkIcon />
          <p className="text-sm font-bold">Save {discountPercentage}%</p>
        </div>
      )}
      <Button className="w-full hidden md:block" variant="primary" onClick={() => onSelect(code)}>
        Select
      </Button>
      {planDetails && (
        <UserPlanBenefits
          data-testid="user-plan-dropdown"
          className={twMerge('p-0', !dfMdMedia && selected ? 'bg-[#E6E6E6]' : 'bg-white')}
          details={planDetails}
          defaultOpen
          hideTriggerButton
        />
      )}
    </div>
  );
};

const SelectPlan: React.FunctionComponent = (): JSX.Element => {
  const navigate = useHistory();
  const clientCode = String(sessionStorage.getItem('clientCode'));

  const { data: subscriptions, isLoading } = useSubscriptions(clientCode);

  const dfMdMedia = useMediaQuery('md');

  const [selectedPlanCode, setSelectedPlanCode] = useState(null);

  const handleSelectPlan = (planCode: any) => {
    setSelectedPlanCode(selectedPlanCode !== planCode ? planCode : null);
  };

  const saveSelectedPlanCode = useCallback(
    (planCode: any = selectedPlanCode) => {
      if (planCode) {
        localStorage.setItem('planCode', planCode);
        navigate.push(ROUTE_PATH.ONBOARDING_PAYMENT);
      }
    },
    [navigate, selectedPlanCode],
  );

  return (
    <div className="min-h-screen flex flex-col">
      <DesktopHeader className="hidden md:block" />
      <MobileHeader className="md:hidden" onBack={() => navigate.goBack()} />
      <div className="bg-white h-full grow md:bg-whiteSmoke">
        <div className="mx-auto md:max-w-7xl md:px-6">
          <SubHeader className="hidden mt-6 md:block" title="" onBackButtonClick={() => navigate.goBack()} />
          <div className="font-manrope text-gray-900 px-5 py-8 md:text-center md:pt-0">
            <span className="text-2xl font-bold md:text-3xl">Select Plan</span>
            <p className="text-base font-normal flex flex-wrap gap-2 md:hidden">
              Have any questions? Check our
              <button onClick={() => {}} className="flex text-violet-950 font-semibold items-center cursor-pointer">
                FAQ's &nbsp;
                <CircledArrowRightIcon />
              </button>
            </p>
          </div>
          {isLoading ? (
            <div className="w-full h-50 flex items-center justify-center">
              <SpinnerIcon />
            </div>
          ) : (
            <>
              <div
                className={twMerge(
                  'grid grid-cols-1 gap-6 md:pb-8',
                  (subscriptions?.length || 0) === 1 && 'md:place-items-center',
                  (subscriptions?.length || 0) === 2 && 'md:grid-cols-2',
                  (subscriptions?.length || 0) >= 3 && 'md:grid-cols-3',
                )}
              >
                {subscriptions?.map((subscription) => (
                  <div className="space-y-4" key={subscription.code}>
                    <PaymentPlan
                      code={subscription.code}
                      selected={selectedPlanCode === subscription.code}
                      onSelect={dfMdMedia ? saveSelectedPlanCode : handleSelectPlan}
                      className="
                            py-5 px-4 border-t border-b border-neutral-200
                            md:rounded-lg md:border md:p-6
                          "
                      banner={subscription.banner_text}
                      minutes={subscription.minutes}
                      discountPercentage={subscription.discount}
                      planDescription={subscription.description}
                      planPrice={subscription.price / 100}
                      planDetails={subscription.details}
                    />
                  </div>
                ))}
              </div>
              <div className="py-3 px-4 md:hidden">
                <Button
                  data-testid="select-plan-button"
                  className="w-full"
                  variant="primary"
                  onClick={() => saveSelectedPlanCode()}
                  disabled={selectedPlanCode === null}
                >
                  Select plan
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectPlan;
