import { useState } from 'react';

import { UserPlan, UserPlanBalance, UserPlanRenewsDate } from '../../../common/user_plan';
import { FormBlock } from '../../../common/form_block';

import { CancelUserPlanModal } from '../../../CancelUserPlanModal';

import { useUser } from '../../../../hooks/useUser';

import { TabHeader } from '../tab_header';
import { SpinnerIcon } from '@kindlyhuman/component-library';

export const MyPlanTab = () => {
  const { data: user, isLoading: isUserLoading } = useUser();

  const [showCancelUserPlanModal, setShowCancelUserPlanModal] = useState<boolean>(false);

  const userPackage = user?.caller_role.active_subscription?.package;
  const logoImage = userPackage?.client.logo_file_url || '';
  const planName = userPackage?.name || '';
  const groupName = userPackage?.client.name || '';

  if (isUserLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <>
      <TabHeader header="My plan" subHeader={`You’re currently on the ${planName} plan provided by ${groupName}.`} />
      {logoImage && (
        <div className="border rounded-[10px] bg-white border-neutral-200 py-6">
          <img className="max-h-24 mx-auto" src={logoImage} alt="Group Logo" />
        </div>
      )}
      {!!user && (
        <div className="space-y-2">
          <FormBlock className="mt-6">
            <UserPlan user={user} />
          </FormBlock>
          {user?.user_subscription?.remaining_minutes && user?.onCappedPlan && (
            <FormBlock>
              <UserPlanBalance user={user} />
            </FormBlock>
          )}
          {(user?.onCappedPlan || user?.onMemberPayPlan) && (
            <FormBlock>
              <UserPlanRenewsDate />
            </FormBlock>
          )}
          {user?.onMemberPayPlan && (
            <button className="text-primary fo t-bold !mt-4" onClick={() => setShowCancelUserPlanModal(true)}>
              Cancel plan
            </button>
          )}
        </div>
      )}
      <CancelUserPlanModal open={showCancelUserPlanModal} onClose={() => setShowCancelUserPlanModal(false)} />
    </>
  );
};
