import React, { useEffect, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { SpinnerIcon, ToggleWithLabel } from '@kindlyhuman/component-library';

import { User, useUser } from '../../../../hooks/useUser';

import Toast from '../../../common/PopUpMessage';
import { FormBlock } from '../../../common/form_block';

import { TabHeader } from '../tab_header';

export interface NotificationsProfileData {
  notifications_listener_online: boolean;
  notifications_check_ins: boolean;
}

export const NotificationsTab = () => {
  const toastNotificationContainerRef = useRef<HTMLDivElement>(null);

  const { data: user, isLoading: isUserLoading, updateUser } = useUser();

  const generateDefaultValues = (user: User | undefined): Partial<NotificationsProfileData> => ({
    notifications_listener_online: user?.caller_role?.notifications_listener_online,
    notifications_check_ins: user?.caller_role?.notifications_check_ins,
  });

  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: React.useMemo(() => {
      return generateDefaultValues(user);
    }, [user]),
    mode: 'onChange',
  });

  useEffect(() => {
    if (user) {
      reset(generateDefaultValues(user));
    }
  }, [user, reset]);

  const submit: SubmitHandler<Partial<NotificationsProfileData>> = async (data) => {
    updateUser.mutate(
      {
        caller_role: {
          ...user?.caller_role!,
          notifications_listener_online: data.notifications_listener_online!,
          notifications_check_ins: data.notifications_check_ins!,
        },
      },
      {
        onSuccess: () => {
          Toast.success('Profile updated successfully', undefined, toastNotificationContainerRef?.current);
        },
      },
    );
  };

  if (isUserLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <>
      <div ref={toastNotificationContainerRef} />
      <TabHeader header="Notifications" />
      <div className="space-y-4">
        <FormBlock>
          <ToggleWithLabel
            header="My Listeners"
            subHeader="Receive reminders when my Listeners are on-call"
            checked={watch('notifications_listener_online') ?? false}
            {...register('notifications_listener_online')}
            onChange={(checked: boolean) => {
              setValue('notifications_listener_online', checked, { shouldDirty: true });
              handleSubmit(submit)();
            }}
          />
        </FormBlock>
        <FormBlock>
          <ToggleWithLabel
            header="Reflection Reminders"
            subHeader="Allow occasional reminders to take a moment and reflect"
            checked={watch('notifications_check_ins') ?? false}
            {...register('notifications_check_ins')}
            onChange={(checked: boolean) => {
              setValue('notifications_check_ins', checked, { shouldDirty: true });
              handleSubmit(submit)();
            }}
          />
        </FormBlock>
      </div>
    </>
  );
};
