import { FunctionComponent, useState } from 'react';
import {
  Button,
  CheckmarkIcon,
  CircledArrowRightIcon,
  DesktopHeader,
  MobileHeader,
  SpinnerIcon,
} from '@kindlyhuman/component-library';
import { Link, useHistory } from 'react-router-dom';
import { SubHeader } from '../../../components/MenuHeader';
import { useSubscription } from '../../../hooks/useSubscription';
import { UserPlanBenefits } from '../../../components/common/user_plan';
import { FormBlock, formBlockClassName } from '../../../components/common/form_block';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { AddPaymentForm } from '../../../components/common/payment/add_payment_form';
import { PoweredByStripe } from '../../../components/common/payment/powered_by_stripe';
import { twMerge } from 'tailwind-merge';
import Toast from '../../../components/common/PopUpMessage';

export const OnboardingPayment: FunctionComponent = (): JSX.Element => {
  const [paymentSucceeded, setPaymentSucceeded] = useState<boolean>(false);

  const navigate = useHistory();

  return (
    <div className="min-h-screen flex flex-col">
      <DesktopHeader className="hidden md:block" />
      <MobileHeader className="md:hidden" onBack={() => navigate.goBack()} />
      <div className={twMerge('bg-white h-full grow', paymentSucceeded ? 'flex flex-col' : 'md:bg-whiteSmoke')}>
        {!paymentSucceeded ? (
          <OnboardingPaymentStep
            className="mx-auto md:max-w-7xl md:px-6"
            onSuccessPayment={() => setPaymentSucceeded(true)}
          />
        ) : (
          <div className="text-center px-5 max-w-124 mx-auto flex flex-col items-center justify-center h-full grow">
            <p className="text-primary text-2xl font-bold">Payment success!</p>
            <p className="mt-2">
              A confirmation email has been sent to you. We are thrilled to have you start interacting with our
              community of peers.
            </p>
            <Link to={ROUTE_PATH.ONBOARDING} replace>
              <Button variant="primary" className="mt-8">
                Continue
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

interface OnboardingPaymentStepProps {
  className?: string;
  onSuccessPayment?: () => void;
}

const OnboardingPaymentStep: FunctionComponent<OnboardingPaymentStepProps> = ({
  className,
  onSuccessPayment,
}): JSX.Element => {
  const planCode = String(localStorage.getItem('planCode'));

  const { data: subscription, isLoading } = useSubscription(planCode);
  const { minutes, price, description, discount: discountPercentage, details } = subscription || {};
  const planPrice = (price || 0) / 100;

  const navigate = useHistory();

  const onSubmitPayment = (stripePaymentMethod: string) => {
    // TODO: add subscription logic when this functionality will work
    return new Promise((res) => {
      if (stripePaymentMethod) {
        localStorage.setItem('stripePaymentMethod', stripePaymentMethod);
        res(null);
        if (onSuccessPayment) {
          onSuccessPayment();
        }
      }
    });
  };

  return (
    <div className={twMerge('', className)}>
      <SubHeader className="hidden mt-6 md:block" title="" onBackButtonClick={() => navigate.goBack()} />
      <div className="font-manrope text-gray-900 px-5 py-8 md:text-center md:pt-0">
        <span className="text-2xl font-bold md:text-3xl">Payment {subscription?.banner_text}</span>
        <p className="text-base font-normal flex flex-wrap gap-2 md:hidden">
          Have any questions? Check our
          <button onClick={() => {}} className="flex text-violet-950 font-semibold items-center cursor-pointer">
            FAQ's &nbsp;
            <CircledArrowRightIcon />
          </button>
        </p>
      </div>
      {isLoading ? (
        <div className="w-full h-50 flex items-center justify-center">
          <SpinnerIcon />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-2 mb-10 md:grid-cols-2 md:gap-6 md:mb-0">
          <div className="order-2 md:order-1">
            <AddPaymentForm
              className={twMerge('border-t border-b', formBlockClassName)}
              onSubmitPayment={onSubmitPayment}
              bottomActionButton={({ isSubmitting }) => (
                <>
                  <FormBlock className="p-6 border-t border-b flex items-center justify-between mt-2 text-gray-800 space-y-0">
                    <span className="text-xl font-bold">Total</span>
                    <span className="text-base font-medium">${planPrice}.00</span>
                  </FormBlock>
                  <div
                    className="
                          flex flex-col items-center justify-center gap-8 px-1 mt-4
                          md:flex-row md:justify-between
                        "
                  >
                    <PoweredByStripe />
                    <Button className="" variant="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
                      Subscribe & Checkout
                    </Button>
                  </div>
                </>
              )}
              onErrorAddPayment={() => {
                Toast.error(
                  'Your payment method has failed to process. Confirm your payment info is correct and try again.',
                  'Payment Failed',
                );
              }}
            />
          </div>
          <FormBlock className="p-6 border-t border-b order-1 md:order-2">
            <div className="text-gray-800 font-medium space-y-2">
              <div className="flex gap-4 justify-between">
                <div className="space-y-2">
                  <p className="text-base">{minutes} minutes</p>
                  <p className="text-xl font-bold md:text-2xl">${planPrice} / month</p>
                </div>
                <Link to={ROUTE_PATH.SELECT_PLAN}>
                  <Button className="px-3 py-1 text-sm" variant="secondary">
                    Change
                  </Button>
                </Link>
              </div>
              <p className="text-sm">{description}</p>
            </div>
            {!!discountPercentage && (
              <div className="flex items-center gap-2">
                <CheckmarkIcon />
                <p className="text-sm font-bold">Save {discountPercentage}%</p>
              </div>
            )}
            {details && (
              <UserPlanBenefits
                data-testid="user-plan-dropdown"
                className="py-0 px-2"
                details={details}
                defaultOpen
                hideTriggerButton
              />
            )}
          </FormBlock>
        </div>
      )}
    </div>
  );
};
